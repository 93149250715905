import React from 'react'
import Gigs from '../../components/gigs'
import { graphql } from 'gatsby';

export default (props) => (
  <Gigs
    {...props}
    events={props.data.thirdPartyEvents.collection}
    currentLanguage='fr'
  />
)

export const GigsFrQuery = graphql`
  query GigsFrQuery {
    thirdPartyEvents {
      collection {
        eventId
        place {
          en
          fr
        }
        dates {
          from
          to
        }
        description {
          en
          fr
        }
      }
    }
  }
`
