import React from 'react'
import intl from 'react-intl-universal';
import { Container } from 'reactstrap';
import moment from 'moment'

const EventRow = (props) => (
  <tr key={props.eventId}>
    <td>
      {intl.get('date', { date: moment(props.event.dates.from).toDate() })}
    </td>
    <td>
      {props.event.place[props.currentLanguage]}
    </td>
    <td dangerouslySetInnerHTML={{__html: props.event.description[props.currentLanguage]}}>
    </td>
  </tr>
);

const Gigs = (props) => (
  <div className="page page-regular page-gigs">
    <Container>
      <h2>{intl.get('pageGigs')}</h2>
      <table className="table table-sm">
        <thead>
          <tr>
            <th>{intl.get('eventDate')}</th>
            <th>{intl.get('eventPlace')}</th>
            <th>{intl.get('eventDetails')}</th>
          </tr>
        </thead>
        <tbody>
          {
            props
              .events
              .filter(x => moment(x.dates.from).isAfter(moment().subtract(1, 'day'), 'day'))
              .sort((a, b) => moment(a.dates.from).isBefore(b.dates.from) ? -1 : 1)
              .map(x => <EventRow key={x.eventId} event={x} currentLanguage={props.currentLanguage} />)
          }
        </tbody>
      </table>
    </Container>
  </div>
)

export default Gigs;
